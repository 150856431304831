<template>
  <div>
    <!-- Background circulos ////////////////// -->
    <div class="bg-cercles">
      <!-- Spinner loading -->
      <div class="bb-spinner" v-if="!listAvailable">
        <div class="bb-spiner__content">
          <div class="bb-spinner__dots">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <!-- Spinner loading -->

      <template v-else>
        <!-- Section Header Foto, nombre y mensaje ////////////////// -->
        <section class="list-header">
          <div class="list-header__avatar">
            <img
              v-show="myList.photo === null"
              src="../../assets/images/embarazada.jpg"
              width="108"
              height="108"
              srcset="
                ../../assets/images/embarazada-p-500.jpeg   500w,
                ../../assets/images/embarazada-p-800.jpeg   800w,
                ../../assets/images/embarazada-p-1080.jpeg 1080w,
                ../../assets/images/embarazada.jpg         1314w
              "
              sizes="(max-width: 479px) 100vw, 200px"
              alt
            />
            <img
              v-show="myList.photo !== null"
              :src="getImgUrl()"
              width="108"
              height="108"
              alt=""
            />
          </div>
          <div class="bb-container">
            <div class="list-header__head">
              <h1 class="list-header__name">
                {{ listTitle }}
              </h1>
              <div class="list-header__msg">
                {{ welcomeMessage }}
              </div>
            </div>
          </div>
        </section>

        <!--
        <section>
          <div v-if="isBetaUser">
            <adsense></adsense>
          </div>
        </section>
        -->

        <!-- Section Fondos economicos ////////////////// -->
        <div class="" v-show="funds && funds.length > 0">
          <div class="bb-container bb-container--lista">
            <div class="list-section">
              <div class="list-section__header">
                <div class="bb-accordion__title">
                  <h3 class="header-3">{{ $t("guest.contributeInAFund") }}</h3>
                </div>
              </div>

              <div class="list-section__content mar-b-3">
                <p class="mar-b-2">
                  {{ $t("guest.contributeInAFundText") }}
                </p>
                <div class="grid-fondos-cards">
                  <ListFondosCard
                    v-for="(fund, index) in funds"
                    :fund="fund"
                    :key="index"
                    :list="myList"
                  ></ListFondosCard>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Sección regalos ////////////////// -->
        <div class="" v-show="showProducts" id="price_range_filter">
          <div class="bb-container bb-container--lista">
            <div class="list-section">
              <div class="list-section__header">
                <h3 class="header-3">{{ $t("guest.makeAGift") }}</h3>
              </div>
              <div class="list-section__filters">
                <div class="list-guest-filters">
                  <mq-layout :mq="['m992', 'm1200']">
                    <div class="list-guest-filters__d">
                      <category-filter></category-filter>
                      <price-range-filter></price-range-filter>
                      <state-filter></state-filter>
                    </div>
                  </mq-layout>
                  <mq-layout :mq="['m480', 'm576', 'm768']">
                    <div class="list-guest-filters__m">
                      <bbButton
                        :label="$t('filters.filter')"
                        icoStart="uil-filter"
                        class="button--outline-dark button--sm"
                        @click="showFiltersModal = true"
                      ></bbButton>
                      <bbButton
                        label="Borrar filtros"
                        icoStart="uil-multiply"
                        class="button--dark button--sm"
                        @click="clearFilters()"
                        v-show="hasFilters"
                      ></bbButton>

                      <modal-dialog
                        :show="showFiltersModal"
                        @close="showFiltersModal = false"
                        target-class="modal__card--mini"
                      >
                        <div class="card-filtros">
                          <h2 class="card-filtros__titulo">{{ $t("filters.filters") }}</h2>
                          <category-filter></category-filter>
                          <price-range-filter></price-range-filter>
                          <state-filter></state-filter>

                          <div class="card-filtros__actions">
                            <button
                              class="button button--primary button--block"
                              @click="showFiltersModal = false"
                            >
                              {{ $t("generic.close") }}
                            </button>
                          </div>
                        </div>
                      </modal-dialog>
                    </div>
                  </mq-layout>
                  <div class="list-guest-filters__search">
                    <search-filter></search-filter>
                  </div>
                </div>
              </div>
              <!-- TODO: Remove the hardcoded `false` when ecommerce gets more priority. -->
              <div v-if="false && hasHelloBBProduct" class="list-section__shopbb">
                <div class="shopbb-features">
                  <div class="shopbb-features__title">
                    <img src="../../assets/img/logos/favicon.svg" alt="" />
                    ¡Cómpralo en la tienda de HelloBB!
                  </div>
                  <div class="shopbb-features__items">
                    <div class="shopbb-features__item">
                      {{ myList.ownerName }} acumulará un 5% de todo lo que gastes.
                    </div>
                    <div class="shopbb-features__item">
                      {{ myList.ownerName }} podrá hacer devoluciones sin problemas.
                    </div>
                    <div style="font-size: small">
                      Recuerda que las compras en otras tiendas y las aportaciones
                      económicas no cuentan.
                    </div>
                  </div>
                </div>
              </div>
              <div class="list-section__content" id="products_starting">
                <div v-show="!productsIsNotEmpty">
                  {{ $t("filters.noFilterResult") }}
                </div>
                <div
                  class="list-section list-section--owner"
                  v-for="(group, index) in productGroups"
                  :key="index"
                >
                  <div
                    :ref="'div_accordion_' + group[0].mainCategoryId"
                    @click="toggle(group[0].mainCategoryId)"
                    class="bb-accordion mar-b-1 pointer"
                  >
                    <div class="bb-accordion__title">
                      <h3 class="header-3 mb-0">
                        {{ group[0].mainCategory }}
                        <small>({{ group.length }})</small>
                      </h3>
                    </div>

                    <div v-if="!filteredByCategories" class="bb-accordion__right">
                      <img
                        :ref="'img_accordion_' + group[0].mainCategoryId"
                        src="../../assets/img/ico/ico-chevron-up.svg"
                        width="16px"
                        height="16px"
                        class="bb-accordion__chevron bb-accordion__chevron--active"
                        alt
                      />
                    </div>
                  </div>

                  <div :ref="group[0].mainCategoryId" class="lpac-list">
                    <template v-for="(product, index) in group">
                      <ListProductCardWAdd
                        :key="product.id"
                        :product="product"
                        :list="myList"
                        @bookedProductSelected="showModalBooked"
                        @updateList="refreshList"
                      />

                      <InFeedAd
                        v-if="
                          howManyProductsTotal >= 5 &&
                          shouldDisplayAds &&
                          shouldDisplayAdAfterItem(index)
                        "
                      />
                  </template>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pad-3" v-show="!showProducts">
          <div class="bb-container bb-container--lista">
            <div class="list-empty">
              <img src="../../assets/img/ico/ico-gift-empty.svg" />
              <p>{{ $t("guest.noProducts") }}</p>
            </div>
          </div>
        </div>

        <!--    MODAL PRODUCTE RESERVAT-->
        <transition name="bbFace" @after-enter="showBookedModalContent = true">
          <div class="modal" v-show="showBookedModal" @click="close()">
            <transition name="bbUp" @after-leave="close()">
              <div v-show="showBookedModalContent" class="modal__card" @click.stop="">
                <div class="modal__dialog">
                  <div class="modal__header">
                    <div class="modal__back"></div>
                    <div class="modal__close">
                      <img
                        src="../../assets/img/ico/ico-close.svg"
                        alt="Cerrar"
                        @click="close()"
                      />
                    </div>
                  </div>
                  <div class="modal__content">
                    <div class="flux-regalo">
                      <div class="flux-regalo__title">
                        Alguien tiene reservado este regalo, ¿eres tú?
                      </div>

                      <div class="flux-regalo__msg">
                        Escribe el email con el que hiciste la reserva para acabar el
                        proceso de compra.
                      </div>

                      <div class="flux-regalo__form mb-0">
                        <div class="bb-form-item">
                          <div class="bb-form-label">Tu email</div>
                          <input
                            type="email"
                            class="bb-form"
                            placeholder="Escribe aquí tu email"
                            v-model.lazy="email"
                            :class="{
                              'bb-form--success': emailIsValid,
                              'bb-form--danger': emailHasError,
                            }"
                            required
                          />
                          <div class="bb-form-error" v-show="emailHasError">
                            Lo sentimos. Hay un error en este campo.
                          </div>
                          <div class="bb-form-error" v-show="this.error">
                            Lo sentimos. No hay ninguna reserva con este email
                          </div>
                        </div>
                        <loading-button v-if="loadingBooking"></loading-button>
                        <button
                          v-else
                          class="button button--primary button--block mar-t-1"
                          @click="next"
                          :disabled="disabled"
                        >
                          Acceder
                        </button>
                      </div>

                      <div class="flux-regalo__actions"></div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </transition>

        <transition name="bbFace" @after-enter="showSuccessModalContent = true">
          <div class="modal" v-show="showSuccessModal" @click="showContent = false">
            <transition name="bbUp" @after-leave="closeSuccessModal()">
              <div
                class="modal__card modal__card--mini"
                v-show="showSuccessModalContent"
                @click.stop=""
              >
                <div class="modal__dialog">
                  <div class="modal__header">
                    <div class="modal__close">
                      <img
                        src="../../assets/img/ico/ico-close.svg"
                        alt="Cerrar"
                        @click="showSuccessModalContent = false"
                      />
                    </div>
                  </div>
                  <div class="modal__content">
                    <div class="card-success">
                      <div class="card-success__ico">
                        <img
                          src="../../assets/img/ico/ico-color-success.svg"
                          alt="¡Gracias!"
                        />
                      </div>
                      <h3 class="card-success__header">¡Aportación realizada!</h3>
                      <div class="card-success__content">
                        Le hemos mandado un email a {{ myList.ownerName }} para avisarle
                        de tu aportación. ¡Seguro que te lo agradece muchísimo!
                      </div>
                      <div class="card-success__actions">
                        <a
                          href="#"
                          @click="showSuccessModalContent = false"
                          class="button button--primary button--w60"
                          >Genial</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </transition>

        <!-- Sección CTA ////////////////// -->
        <div class="pad-3">
          <div class="bb-container">
            <CtaAmigos></CtaAmigos>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ListFondosCard from "./components/ListFondosCard.vue";
//import ListProductCard from "./components/ListProductCard.vue";
import ListProductCardWAdd from "./components/ListProductCardWAdd.vue";
import ListTips from "./components/ListTips.vue";
import CtaAmigos from "../cta/CtaAmigos.vue";
import { mapGetters } from "vuex";
import PriceRangeFilter from "./filters/PriceRangeFilter";
import StateFilter from "./filters/StateFilter";
import CategoryFilter from "./filters/CategoryFilter";
import SearchFilter from "./filters/SearchFilter";
import LoadingButton from "../../components/LoadingButton";
import CanGetGuestList from "../../Mixins/CanGetGuestList";
import CanGetCategories from "../../Mixins/CanGetCategories";
import GuestListFirstVisit from "../mixins/GuestListFirstVisit";
import HeaderList from "../../layout/HeaderList.vue";
import InFeedAd from "../adsense/InFeedAd.vue";

import ModalDialog from "../../components/ui/ModalDialog";
import bbButton from "../../components/buttons/bbButton.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "List",
  mixins: [
    CanGetGuestList,
    GuestListFirstVisit,
    CanGetCategories,
  ],
  components: {
    HeaderList,
    ListFondosCard,
    //ListProductCard, // TODO: Eliminar cuando siguiente este lista
    ListProductCardWAdd, // TODO: Nueva tarjeta de producto
    CtaAmigos,
    ListTips,
    InFeedAd,
    bbButton,
    "price-range-filter": PriceRangeFilter,
    "state-filter": StateFilter,
    "category-filter": CategoryFilter,
    "search-filter": SearchFilter,
    "loading-button": LoadingButton,
    "modal-dialog": ModalDialog,
  },
  computed: {
    ...mapGetters({
      guestProductFilters: "guestProductFilters",
      myList: "myList",
      shouldDisplayAds: "shouldDisplayAds",
      listUpdated: "listUpdated",
    }),
    welcomeMessage() {
      return this.myList.welcomeMessage || this.DEFAULT_WELCOME_MESSAGE;
    },
    emailIsValid() {
      if (this.email === "") return false;
      return this.$utils.validateEmail(this.email);
    },
    emailHasError() {
      return !!(this.email !== "" && !this.$utils.validateEmail(this.email));
    },
    showProducts() {
      if (this.guestProductFilters?.length > 0) return true;
      return this.productsIsNotEmpty;
    },
    productsIsNotEmpty() {
      return this.products && this.products.length > 0;
    },
    disabled() {
      return !this.$utils.validateEmail(this.email);
    },
    listTitle() {
      return this.myList.title || "Lista de " + this.myList.ownerName;
    },
    title() {
      return this.myList.title;
    },
    funds() {
      return this.myList.funds || [];
    },
    products() {
      let products = this.myList.products || [];

      if (products.length > 0) {
        if (this.guestProductFilters) {
          this.guestProductFilters?.forEach((filter) => {
            if (filter.name === "state" && filter.values?.id !== 99) {
              products = products.filter((product) => product.status == filter.values.id);
            }
            if (filter.name === "priceRange") {
              products = products.filter((product) => {
                const minPrice = this.minPrice(product);
                return (
                  minPrice >= filter.values.priceRange.min &&
                  minPrice <= (filter.values.priceRange.max || 9999999)
                );
              });
            }
            if (filter.name === "category") {
              products = products.filter((product) => (
                product.mainCategoryId === filter.values?.id
              ));
            }
            if (filter.name === "search") {
              products = products.filter((product) => {
                return product.name
                  .toLowerCase()
                  .includes(filter.values.textToSearch?.toLowerCase());
              });
            }
          });
        }
      }

      // Using `.map` to avoid mutating the original product objects.
      products = products.map((product) => ({
        ...product,

        mainCategory: this.getTranslatedCategory(product.mainCategoryId) || product.mainCategory,
      }));

      return products;
    },
    howManyProductsTotal() {
      return this.products.length;
    },
    hasFilters() {
      return (
        this.guestProductFilters &&
        Array.isArray(this.guestProductFilters) &&
        this.guestProductFilters.length > 0
      );
    },
    productGroups() {
      if (this.products.length === 0) {
        return [];
      }

      return this.products.reduce((result, product) => {
        let group = result[product.mainCategory] || [];
        group.push(product);

        group = this.sortProductGroup(group);

        result[product.mainCategory] = group;

        return result;
      }, Object.create(null));
    },
    filteredByCategories() {
      if (this.guestProductFilters) {
        const found = this.guestProductFilters?.find((filter) => {
          return filter.name === "category";
        });
        if (found) return true;
      }
      return false;
    },
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [{ name: "robots", content: "noindex" }],
    };
  },
  data() {
    return {
      toggleFund: true,
      showBookedModal: false,
      showBookedModalContent: false,
      showSuccessModal: false,
      showSuccessModalContent: false,
      showFiltersModal: false,
      email: "",
      selectedProduct: null,
      error: false,
      reservation: null,
      loadingBooking: false,
      listAvailable: false,
      hasHelloBBProduct: null,
      selected: "regalar",
      tabs: [
        {
          key: "regalar",
          name: "Regalar un objeto",
          ico: "uil-baby-carriage",
        },
        {
          key: "fondo",
          name: "Aportar a un fondo",
          ico: "uil-coins",
        },
      ],
      tips: ["acumular-pic", "acumular-text", "cambios-pic", "cambios-text"],
    };
  },
  methods: {
    async getList(server = false, id = null) {
      return await this.$store.dispatch("getListGuest", {
        id: id ? id : this.$route.params.id,
        server,
      });
    },
    refreshList() {
      this.getList(false, this.$route.params.id);
      //scroll to top
      window.scrollTo(0, 0);
    },
    async getListFromServer() {
      return await this.getList(true);
    },
    getId(product) {
      return product.isFree ? "free_" + product.id : "catalogue_" + product.id;
    },
    close() {
      this.showBookedModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
      document.getElementById(this.getId(this.selectedProduct)).scrollIntoView();
    },
    async checkEmail() {
      let result;
      if (this.selectedProduct.isFree) {
        result = await this.$store.dispatch("checkEmailFreeProduct", {
          email: this.email,
          product: this.selectedProduct,
        });
      } else {
        result = await this.$store.dispatch("checkEmail", {
          email: this.email,
          product: this.selectedProduct,
        });
      }

      if (result?.data) {
        this.reservation = result.data;
        return true;
      }
      return false;
    },
    async next() {
      this.loadingBooking = true;
      let result = false;
      result = await this.checkEmail();
      this.loadingBooking = false;
      if (result) {
        this.error = false;
        document.body.classList.remove("modal-open");
        this.$router.push({
          name: "guestProduct",
          params: {
            id: this.myList.id,
            productId: this.selectedProduct.isFree
              ? this.selectedProduct.id
              : this.selectedProduct.listProductId,
            //productType: this.selectedProduct.isFree ? "free" : "catalog",
            productType: this.selectedProduct.isFree
              ? "free"
              : this.selectedProduct.retailerName != "HelloBB"
              ? "catalog"
              : "hellobb",
          },
          query: this.reservation,
        });
      }
      this.error = true;
    },
    showModalBooked(product) {
      this.selectedProduct = product;
      document.body.classList.add("modal-open");
      this.showBookedModal = true;
    },
    minPrice(product) {
      if (product.isFree) return product.price;
      return Math.min(
        ...product.productRetailers.map((productRetailer) => productRetailer.price)
      );
    },
    getImgUrl() {
      if (this.myList.photo == "" || this.myList.photo == null) {
        return "#";
      }
      return process.env.URL_IMG_USER + this.myList.photo;
    },
    setSelected(tab) {
      this.selected = tab;
    },
    openSuccessModal() {
      document.body.classList.add("modal-open");
      this.showSuccessModal = true;
    },
    closeSuccessModal() {
      document.body.classList.remove("modal-open");
      this.showSuccessModal = false;
    },
    getFundIdFromStatus(status) {
      const prefix = "success-fund-";
      if (status.indexOf(prefix) !== 0) {
        return null;
      }

      const rawFundId = status.substring(prefix.length);
      const fundId = this.$utils.strictParseInt(rawFundId);

      return fundId;
    },
    shouldDisplayAdAfterItem(listItemIndex) {
      let i = 1 + listItemIndex;
      function advance(n) {
        i -= n;
      }

      advance(2);
      if (i === 0) {
        return true;
      }

      advance(3);
      if (i === 0) {
        return true;
      }

      do {
        advance(5);
      } while (i > 0);

      return i === 0;
    },
    clearFilters() {
      this.$setGuestProductFilters([]);
    },
    loadSkimlinkScript() {
      if (!document.querySelector('script[src="//s.skimresources.com/js/265303X1750372.skimlinks.js"]')) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//s.skimresources.com/js/265303X1750372.skimlinks.js';
        script.async = true;
        script.onload = () => {
          console.log('Skimlink script loaded successfully.');
        };
        script.onerror = () => {
          console.error('Failed to load the Skimlink script.');
        };
        document.body.appendChild(script);
      }
    },
    isOpen(id) {
      let div = this.$refs[id];
      if (div) {
        return !div[0].hidden;
      }
      return false;
    },
    toggle(id) {
      let div = this.$refs[id];
      let div_accordion = this.$refs["div_accordion_" + id];
      let img_accordion = this.$refs["img_accordion_" + id];
      if (!this.isOpen(id)) {
        div[0].hidden = false;
        div_accordion[0].classList.add("bb-accordionn--open");
        img_accordion[0].classList.add("bb-accordion__chevron--active");
        return;
      }
      div_accordion[0].classList.remove("bb-accordionn--open");
      img_accordion[0].classList.remove("bb-accordion__chevron--active");
      div[0].hidden = true;
    },
    sortProductGroup(group) {
      return Array.from(group).sort((a, b) => {
        // 1. Estado (disponible --> reservado --> comprado)
        // 2. retailer HelloBB arriba
        // 3. Disponibles para comprar arriba (availableForSale = true)
        // 4. "mustHave = true" arriba
        // 5. Precio descendiente

        // This chain of comparisons abuses the fact that `-1` and `1` are
        // truthy, and `0` is falsy.
        return (
          this.compareStatus(a, b)
          || this.compareRetailer(a, b)
          || this.compareAvailability(a, b)
          || this.compareMustHave(a, b)
          || this.comparePrice(a, b)
        );
      });
    },
    compareStatus(a, b) {
      const PENDING = 1;
      const GIFTED = 2;
      const RESERVED = 3;

      const ORDER = [PENDING, RESERVED, GIFTED];

      if (a.contributionStatus === b.contributionStatus) {
        return 0;
      }

      if (ORDER.indexOf(a.contributionStatus) < ORDER.indexOf(b.contributionStatus)) {
        return -1;
      }

      return 1;
    },
    compareRetailer(a, b) {
      const aIsHelloBB = this.productIsFromHellobbRetailer(a);
      const bIsHelloBB = this.productIsFromHellobbRetailer(b);

      const ORDER = [true, false];
      if (aIsHelloBB === bIsHelloBB) {
        return 0;
      }

      if (ORDER.indexOf(aIsHelloBB) < ORDER.indexOf(bIsHelloBB)) {
        return -1;
      }

      return 1;
    },
    compareAvailability(a, b) {
      if (a.availableForSale === b.availableForSale) {
        return 0;
      }
      if (a.availableForSale) {
        return -1;
      }
      return 1;
    },
    compareMustHave(a, b) {
      if (a.mustHave === b.mustHave) {
        return 0;
      }
      if (a.mustHave) {
        return -1;
      }
      return 1;
    },
    comparePrice(a, b) {
      return b.price - a.price;
    },
    productIsFromHellobbRetailer(product) {
      // TODO: There's probably a better way to check for the retailer.
      return (product.retailerName || "").toLowerCase() === "hellobb";
    },
  },
  created() {
    const filtersQS = this.$route.query.filters;
    if (typeof filtersQS === "string" || filtersQS instanceof String) {
      const filters = JSON.parse(filtersQS);
      this.$setGuestProductFilters(filters, false);
    } else {
      this.$setGuestProductFilters([], false, false);
    }

    const productsWithHelloBB = this.products.some(
      (product) => product.retailerName === "HelloBB"
    );

    if (productsWithHelloBB) {
      this.DEFAULT_WELCOME_MESSAGE =
        "¡Hola! Hemos creado esta lista con cosas que nos hacen falta para cuando llegue nuestro bebé. Ten en cuenta que si compras directamente en HelloBB, acumularemos dinero y no tendremos problemas con posibles devoluciones. ¡Muchas gracias!";
    } else {
      this.DEFAULT_WELCOME_MESSAGE =
        this.$t("guest.defaultMessage")
    }
  },
  beforeMount() {
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    };
    document.addEventListener("keydown", escapeHandler);
    // eslint-disable-next-line vue/no-deprecated-events-api
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
  },
  async serverPrefetch() {
    return await this.getListFromServer();
  },
  async mounted() {
    if(this.myList.locationCountry != "ES") this.loadSkimlinkScript();
    this.hasHelloBBProduct = this.products.some(
      (product) => product.retailerName === "HelloBB"
    );
    const preview = this.$route.query.preview;
    const source = this.$route.query.source;
    const vista = this.$route.query.vista;
    const hash = this.$route.hash;

    console.log("preview", preview);
    console.log("vista", vista);
    console.log("source", source);
    console.log("hash", hash);

    if (
      this.hasHelloBBProduct &&
      this.isFirstTime &&
      !preview &&
      !vista &&
      !source &&
      hash != "#products_starting"
    ) {
      this.$router.push({ path: `/guest/${this.$route.params.id}/1` });
    }

    if (!this.myList) {
      await this.getList();
    } else {
      if (!this.myList.server) {
        await this.getList();
      }
    }

    this.listAvailable = true;

    if (this.guestProductFilters?.length > 0) this.loading = false;

    window.mixpanel.identify(this.myList.userId);
    window.mixpanel.track("Visita llista compartida", {
      "List name": this.myList.title,
      "List owner": this.myList.ownerName,
      "List URL": "https://www.hellobb.net/lista/guest/" + this.myList.id,
    });

    //execute only if there is a hash in the url
    if (window.location.hash == "#products_starting") {
      this.$nextTick(() => {
        // Get the offset from the top of the "products_starting" section
        //const headerHeight = document.querySelector('.list-header').offsetHeight;
        const headerHeight = 80;
        const section = document.getElementById("products_starting");

        if (section) {
          const offset = section.offsetTop - headerHeight;

          // Scroll to the section with smooth behavior
          window.scrollTo({ top: offset, behavior: "smooth" });
        }
      });
    }

    //execute only if there is a status value in the querystring
    let status = this.$route.query.status;
    if (status) {
      if (status.includes("success")) {
        const fundId = this.getFundIdFromStatus(status);

        const fund = this.myList.funds.find((fund) => fund.id === fundId);
        if (fund) {
          this.$nextTick(() => {
            this.openSuccessModal();
          });
        } else {
          this.$store.commit(
            "setConfirmationMessage",
            "¡Gracias por tu apoyo! La aportación se ha realizado correctamente."
          );
          this.closeSuccessModal();
        }
      } else if (status.includes("cancelled")) {
        this.closeSuccessModal();
      }

      let id = status.split("-")[2];
      let type = status.split("-")[1];
      this.$nextTick(() => {
        const headerHeight = 80;
        const section = document.getElementById(type + "_" + id);
        if (section) {
          const offset = section.offsetTop - headerHeight;
          window.scrollTo({ top: offset, behavior: "smooth" });
        }
      });
    }
  },
  watch: {
    listUpdated() {
      if (!this.listUpdated) this.refreshList();
    },
  },
};
</script>
<style scoped></style>
